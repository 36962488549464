import { render, staticRenderFns } from "./shaoer.vue?vue&type=template&id=42213a63&scoped=true&"
import script from "./shaoer.vue?vue&type=script&lang=js&"
export * from "./shaoer.vue?vue&type=script&lang=js&"
import style0 from "./shaoer.vue?vue&type=style&index=0&id=42213a63&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42213a63",
  null
  
)

export default component.exports