<template>
    <div class="index">
        <div class="index-content">
            <div class="mechanism">
                <p class="mechanism-title">少儿部</p>
                <div class="mechanism-list">
                    <div class="mechanism-item" v-for="(item, index) in list" :key="index" @click="navto(item)">
                        <div class="mechanism-item-img">
                            <img :src="item.image" />
                        </div>
                        <div class="mechanism-item-content">
                            <div class="mechanism-item-content-title">{{ item.title }}</div>
                            <p class="mechanism-item-content-p">课程简介：{{ item.describe}}</p>
                        </div>
                    </div>
                </div>
                <!-- 底部分页 -->
                <paging @changeIndex="changeIndex" :allNum="total"></paging>
            </div>
        </div>
    </div>
</template>
<script>
import paging from '@/components/paging'
export default {
    name: 'mechanism',
    components:{
        paging
    },
    data(){
       return{
          list:[],
          page:1,
          limit:6,
          total:0,//总页数
          count:0,//总条数
       } 
    },
    created(){
        this.getList()
    },
    methods: {
        getList(){
            this.$request({
                url:'/api/article/teaching2',
                method:'POST',
                data:{
                    page:this.page,
                    limit:this.limit
                }
            }).then(res=>{
                if(res.code==1){
                    console.log(res)
                    this.list=res.data.list
                    this.count=res.data.count
                    this.total=res.data.total
                }
            })
        },
        navto(item) {
            this.$router.push({
                path: "/mechanismDetail",
                query: {
                    id: item.id
                }
            })
        },
        changeIndex(e){
            console.log(e)
            this.page=e
            this.getList()  
        },
    }
}
</script>
<style lang="scss" scoped>
    .index{
        width: 100vw;
    }
    .index-content{
        width: 100%;
        background: #F6F6EA;
        display:flex;
        justify-content: center;
    }
    .mechanism{
        width: 1200px;
        padding: 60px 40px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
    }
    .mechanism-title{
        text-align: center;
        font-weight: bold;
        color: #272727;
        font-size: 30px;
        padding-bottom: 50px;
    }
    .mechanism-list{
        display: flex;
        flex-direction: column;
        padding-bottom: 50px;
    }

    .mechanism-item{
        display: flex;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #ccc;
    }
    .mechanism-item-img{
        width: 288px;
        height: 197px;
        img{
            width: 288px;
            height: 197px;
        }
    }
    .mechanism-item-content{
        padding-left: 43px;
        display: flex;
        flex-direction: column;
        color: #555655;
    }
    .mechanism-item-content-title{
        font-weight: 500;
        padding-bottom: 30px;
        font-size: 20px;
        color: #272727;
    }
    .mechanism-item-content:hover{
        color: #26716C;
        cursor: pointer;
    }
    .mechanism-item-content-p{
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
    }
</style>